.header-container {
  width: 100%;
  height: 80px;
  background-image: linear-gradient(to right, #043bc7, #1d1e20);
  display: flex;
  align-items: center;
}
.header-container .header-content {
  margin: auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-container .navbar-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-container .navbar-list li {
  margin: 0 10px;
}
.header-container .navbar-list a {
  color: white;
  text-decoration: none;
  font-size: 17px;
}

.header-logo {
  height: 50px;
}

.blue-button {
  background: #0a56f7;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  padding: 8px 20px;
}/*# sourceMappingURL=Header.css.map */